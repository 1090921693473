import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'


//Production
//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')

const rootElement = document.getElementById('root')

ReactDOM.render( 
  <BrowserRouter basename = "%PUBLIC_URL%/">
    <App />
  </BrowserRouter>, rootElement
)

//Dev

/*
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
*/

